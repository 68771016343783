import {storeToRefs} from "pinia";
import {useUserStore} from "~~/stores/user";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore();
    const {user} = storeToRefs(userStore);

    try {
        // console.log("to.query", to.query, "from.query", from.query, "from.path", from.path, "to.path", to.path);
        // console.log("profile setup middleware");
        await userStore.getUser();
        return true;

    } catch (error) {
        return navigateTo("/v2/login");
    }
});
